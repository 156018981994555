<!-- 人才政策 -->
<template>
  <div class="lists centebox">
    <Breadcrumb Otitle="人才政策" />
    <ul v-show="declarelist">
      <li v-for="(item, k) in declarelist" :key="k">
        <span>{{ item.createTime | formatDate }}</span>
        <a @click="requestNotice(item.id)" style="cursor: pointer">{{
          item.title
        }}</a>
      </li>
    </ul>
    <div class="hr-10"></div>
    <el-pagination
      background
      @current-change="collChange"
      layout="prev, pager, next"
      :total="total"
      style="margin: 16px 0 0 0"
    ></el-pagination>
    <div class="hr-10"></div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/WebPanel/Breadcrumb.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      declarelist: [],
      pageSize: 10,
      total: 1,
      page: 1,
      pages: 0,
      activeNames: ["1"],
      newData: {
        title: "",
        content: "",
      },
      newDatalist: [],
      tableData: {
        columnId: "",
      },
      form: {
        columnId: 0,
        title: "",
        type: "",
        status: 0,
        content: "",
      },
    };
  },
  created() {
    this.Getdeclarelist(); //初始化数据
  },

  //用于存放所有的事件方法集合
  methods: {
    Getdeclarelist() {
      this.$axios
        .get("/column/page")
        .then((response) => {
          for (let i = 0; i < response.data.data.records.length; i++) {
            if (response.data.data.records[i].name === "人才政策") {
              this.tableData.columnId = response.data.data.records[i].id;
              this.getIdData(this.tableData.columnId, 1);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getIdData(id, page) {
      let self = this;
      this.$axios
        .get("/article/column", {
          params: {
            columnId: id,
            current: page,
            size: 10,
            status: 1,
          },
        })
        .then((response) => {
          self.total = response.data.data.total;
          self.declarelist = response.data.data.records;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getArticle(id) {
      let self = this;
      this.$axios.get("/article?id=" + id).then((response) => {
        self.declarelist.push(response.data.data);
      });
    },

    requestNotice(id) {
      console.log(id);
      let newPage = this.$router.resolve({
        name: "talent-policy-see",
        params: { id },
      });
      window.open(newPage.href, "_blank");
    },
    //分页
    collChange(val) {
      this.declarelist = [];
      this.getIdData(this.tableData.columnId, val);
    },
  },
};
</script>

<style lang="less" scoped>
.lists {
  background: #fff;
  width: 1156px;
  padding: 16px 24px;
  margin-bottom: 8px;

  ul {
    padding: 8px 20px;
    min-height: 400px;

    li {
      width: 100%;
      height: 40px;
      text-align: left;
      background: url("../../assets/diann.jpg") no-repeat left center;

      a {
        width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        text-decoration: none;
        color: #333;
        line-height: 40px;
        display: block;
        //float: left;
        padding: 0 0 0 16px;
      }

      a:hover {
        color: #c00;
      }

      span {
        font-size: 16px;
        color: #888;
        float: right;
        line-height: 40px;
        display: block;
      }
    }
  }
}
@import "../../styles/common_responsive.less";
</style>
